import React, { useState } from "react"
import styled from "styled-components"
import { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.css"
import "swiper/modules/navigation/navigation.min.css"

import FsLightbox from "fslightbox-react"
const SliderMini = ({ slides = [] }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  if (!slides || !slides.length) {
    return <></>
  }

  const images = slides.map(s => s.imgLarge || s.img)
  function openLightboxOnSlide(slide) {
    const index = images.findIndex(url => url === slide)

    setLightboxController({
      slide: index + 1,
      toggler: !lightboxController.toggler,
    })
  }

  return (
    <>
      <Slider>
        <Swiper
          modules={[Autoplay, Navigation]}
          spaceBetween={50}
          slidesPerView={3}
          speed={1200}
          autoplay={{ delay: 3000 }}
          navigation={{
            nextEl: ".slider-next",
            prevEl: ".slider-prev",
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
        >
          {slides.map(slide => (
            <SwiperSlide>
              <SliderItem>
                <div className="img">
                  <div>
                    <img
                      src={slide.img}
                      alt={slide.title}
                      onClick={() =>
                        openLightboxOnSlide(slide.imgLarge || slide.img)
                      }
                    />
                  </div>
                </div>
                {slide.title && (
                  <div className="body">
                    <h5>{slide.title}</h5>
                  </div>
                )}
              </SliderItem>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="slider-next swiper-button-next" />
        <div className="slider-prev swiper-button-prev" />
      </Slider>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={images}
        slide={lightboxController.slide}
      />
    </>
  )
}

export default SliderMini

export const Slider = styled.div``

export const SliderItem = styled.div`
  box-shadow: 0 3px 12px rgb(0 0 0 / 6%);
  background: white;
  border-radius: 4px;
  overflow: hidden;

  .img {
    padding-top: ${Math.round((350 / 660) * 100)}%;
    position: relative;
    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .body {
    padding: 15px;
  }
`
