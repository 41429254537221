export default [
  [
    "/assets/images/puck/slides/location/IMG_1142.jpg",
    // "/assets/images/puck/slides/location/IMG_1072.jpg",
    // "/assets/images/puck/slides/location/IMG_1075.jpg",
    "/assets/images/puck/slides/location/IMG_1079.jpg",
    "/assets/images/puck/slides/location/IMG_1083.jpg",
    "/assets/images/puck/slides/location/IMG_1125.jpg",
    "/assets/images/puck/slides/location/IMG_1157.jpg",
    "/assets/images/puck/slides/location/IMG_1163.jpg",
    // "/assets/images/puck/slides/location/IMG_1165.jpg",
    "/assets/images/puck/slides/location/IMG_1190.jpg",
    "/assets/images/puck/slides/location/IMG_1195.jpg",
    // "/assets/images/puck/slides/location/IMG_1199.jpg",
    // "/assets/images/puck/slides/location/IMG_1206.jpg",
    "/assets/images/puck/slides/location/IMG_1221.jpg",
    "/assets/images/puck/slides/location/IMG_1226.jpg",
    "/assets/images/puck/slides/location/IMG_1268.jpg",
    "/assets/images/puck/slides/location/IMG_1286.jpg",
    "/assets/images/puck/slides/location/IMG_1403.jpg",
  ],
  [
    "/assets/images/puck/slides/location/mini_IMG_1142.jpg",
    // "/assets/images/puck/slides/location/mini_IMG_1072.jpg",
    // "/assets/images/puck/slides/location/mini_IMG_1075.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1079.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1083.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1125.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1157.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1163.jpg",
    // "/assets/images/puck/slides/location/mini_IMG_1165.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1190.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1195.jpg",
    // "/assets/images/puck/slides/location/mini_IMG_1199.jpg",
    // "/assets/images/puck/slides/location/mini_IMG_1206.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1221.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1226.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1268.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1286.jpg",
    "/assets/images/puck/slides/location/mini_IMG_1403.jpg",
  ],
]
