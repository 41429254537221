import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"

import FsLightbox from "fslightbox-react"
import { MobileButton } from "@/components/shared/button/button"

import img1 from "@/images/z-22/puck-katalog/1.jpg"
import img2 from "@/images/z-22/puck-katalog/2.jpg"
import img3 from "@/images/z-22/puck-katalog/3.jpg"
import img4 from "@/images/z-22/puck-katalog/4.jpg"
import img5 from "@/images/z-22/puck-katalog/5.jpg"
import img6 from "@/images/z-22/puck-katalog/6.jpg"
import img7 from "@/images/z-22/puck-katalog/7.jpg"
import img8 from "@/images/z-22/puck-katalog/8.jpg"

const PuckCatalog = ({ intl }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const slides = [img1, img2, img3, img4, img5, img6, img7, img8]

  return (
    <div>
      <Container className="no-padding">
        <Row>
          <Col xs="12" sm={7} md={5} style={{ margin: "64px auto" }}>
            <MobileButton
              handleAction={() => {
                setLightboxController({
                  slide: 1,
                  toggler: !lightboxController.toggler,
                })
              }}
              bg="#fff"
              border="#94C835"
              color="#94C835"
              arrow={false}
              smallArrow={false}
              height={"auto"}
            >
              <img
                src={img1}
                alt={"Katalog Osiedle Pogodne"}
                width={400}
                height={400}
                style={{ maxWidth: "100%", height: "auto" }}
              />
              <p>Katalog Osiedle Pogodne (online)</p>
            </MobileButton>
            <div className="text-center">
              <a
                href="/dokumenty/Osiedle-Pogodne-folder.pdf"
                target="_blank"
                style={{
                  color: "black",
                  display: "inline-block",
                  margin: "15px auto",
                }}
              >
                Katalog Osiedle Pogodne (PDF)
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <FsLightbox
        toggler={lightboxController.toggler}
        sources={slides}
        slide={lightboxController.slide}
      />
    </div>
  )
}

export default injectIntl(PuckCatalog)
