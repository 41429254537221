import React, { useState } from "react"
import {
  LocalizationContainer,
  PlacesContainer,
} from "../janowotwo/janowoTwoStyle"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"
import localisation from "../../../images/puck/z16-2-puck-lokalizacja.png"
import imagePuck from "@/images/puck/z16-2-0002-tablet.jpg"
// import { MobileButton } from "../../shared/button/button"
import FsLightbox from "fslightbox-react"
import imageDron from "@/images/puck/DRON_01-tablet.jpg"
import imageDronLarge from "@/images/puck/DRON_01.jpg"
import SliderMini from "@/components/slider/sliderMini"
import slidesPuck from "@/data/slides/slidesPuck"

const PuckLocalization = ({ intl }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const slides = slidesPuck[0].map((img, index) => ({
    imgLarge: img,
    img: slidesPuck[1][index],
  }))

  return (
    <LocalizationContainer className="localization">
      <Container className="localization-title" style={{ paddingBottom: 0 }}>
        <Row>
          <Col xs="12" className="no-padding">
            <h1>
              {intl.formatMessage({ id: "offer.janowotwo.localization.title" })}
            </h1>
          </Col>
        </Row>
      </Container>

      <Container className="localization-content">
        <Row>
          <Col xs="12" lg="5" className="no-padding places-title">
            {/*<p>
            <span>Miejsce dla każdego</span>
          </p>*/}
            <p>
              Osiedle Pogodne położone jest w <strong>zacisznej</strong>{" "}
              i&nbsp;jednocześnie{" "}
              <strong>idealnie skomunikowanej dzielnicy Pucka.</strong>{" "}
              Wystarczy niespełna 15 min spaceru, aby dotrzeć do plaży, wzdłuż
              której rozpościera się <strong>widok na Zatokę Pucką.</strong>
            </p>
            <p>
              Jest to miejsce stworzone dla osób ceniących spokój i urok
              otaczającej przyrody. Idealnie odnajdą się tu{" "}
              <strong>fani pieszych i rowerowych wycieczek.</strong> To właśnie
              z Pucka prowadzi <strong>malowniczy szlak rowerowy</strong> na
              Półwysep Helski oraz do Osłonina.
            </p>
            <p>
              Puck jest <strong>perełką żeglarstwa i sportów wodnych.</strong>{" "}
              Nowoczesna marina oddana do użytku w 2023 roku oraz{" "}
              <strong>port jachtowy</strong>, w którym od lat odbywają się
              światowej klasy regaty żeglarskie każdego roku, przyciągają
              turystów z całej Polski.
              <br />
              <br />
              Dla wszystkich fanów kitesurfingu i windsurfingu Zatoka Pucka jest
              idealnym miejscem do realizacji swoich pasji.
            </p>
          </Col>

          <Col xs="12" lg="7" style={{ padding: 0 }}>
            <Container style={{ padding: 0, height: "100%" }} fluid>
              <Row>
                <Col
                  xs="12"
                  className={"raster-image"}
                  style={{ paddingRight: 0 }}
                >
                  <img
                    src={imageDron}
                    alt={"Puck"}
                    style={{ maxWidth: "100%", cursor: "pointer" }}
                    onClick={() =>
                      setLightboxController({
                        slide: 1,
                        toggler: !lightboxController.toggler,
                      })
                    }
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="no-padding places-info places-info--block">
            <PlacesContainer>
              <div>
                <span>2km </span>
                molo w Pucku
              </div>
              <div>
                <span>1500m </span>
                plaża
              </div>
              <div>
                <span>1500m </span>
                zabytkowy rynek
              </div>
              <div>
                <span>2km </span>
                park krajobrazowy
              </div>
              <div>
                <span>50m </span>
                przedszkole
              </div>
              <div>
                <span>1km </span>
                dworzec PKP
              </div>
            </PlacesContainer>
          </Col>
        </Row>
      </Container>

      <div className="slider-wrapper">
        <Container>
          <Row>
            <Col
              style={{ margin: "0 -20px 30px", maxWidth: "calc(100% + 40px)" }}
            >
              <SliderMini slides={slides} />
            </Col>
          </Row>
        </Container>
      </div>

      <Container style={{ margin: "auto", padding: "30px 0 60px" }}>
        <Row>
          <Col xs="12" className="no-padding">
            <div className="loc-map">
              <img src={localisation} alt="Puck Lokalizacja" />
            </div>
          </Col>
        </Row>
      </Container>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={[imageDronLarge]}
        slide={lightboxController.slide}
      />
    </LocalizationContainer>
  )
}

export default injectIntl(PuckLocalization)
